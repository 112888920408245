import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF, { PDFDownloadLink, PDFViewer, PDFRenderer } from '@react-pdf/renderer/dist/react-pdf.es';
import { connect } from 'react-redux';
import { httpGet, getURL } from '../../http';

const PagePos = (props) => {
    
    const { pos } = props;
    
    return(
        <Page size="LETTER" style={styles.pagina}>            
            <View style={styles.header}>
                <Text style={styles.title}>EasyAuto</Text>
                <Text style={styles.subTitle}>INFORME ACUMULACION SERVIDOR POS - { pos.host }</Text>
            </View>
            <View style={styles.bodyContainer}>
                <View style={styles.encabezadoContainer}>
                    <View style={styles.campoContainer}>
                        <Text style={styles.campoTitulo}>FECHA:</Text>
                        <Text>{ pos.createAt }</Text>
                    </View>
                    <View style={styles.campoContainer}>
                        <Text style={styles.campoTitulo}>ESTADO: </Text>
                        <Text>{ pos.state === 1 ? 'TERMINADO CORRECTAMENTE' : pos.state === 2 ? 'SIN ACUMULACION' : 'ERROR EN LA ACUMULACION' }</Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>FASES: </Text>
                        </View>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTitulo}>NOMBRE</Text>
                                <Text style={styles.tablaCampoTitulo}>ESTADO</Text>
                                <Text style={styles.tablaCampoTitulo}>DETALLE</Text>
                                <Text style={styles.tablaCampoTitulo}>FECHA/HORA</Text>
                            </View>
                            {
                                pos.phases.map(
                                    phase => {
                                        return(
                                            <View key={phase._id} style={styles.tr}>
                                                <Text style={styles.tablaCampo}>{ phase.phase }</Text>
                                                <Text style={styles.tablaCampo}>{ phase.state === 1 ? 'TERMINADO CORRECTAMENTE' : pos.state === 2 ? 'SIN ACUMULACION' : 'ERROR EN LA ACUMULACION' }</Text>
                                                <Text style={styles.tablaCampo}>{ phase.message }</Text>
                                                <Text style={styles.tablaCampo}>{ phase.createAt }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                        </View>
                    </View>
                    { ((pos.issues !== null) && (pos.issues !== undefined)) &&
                        <View style={{ marginTop: 20 }}>
                            <View style={styles.campoContainer}>
                                <Text style={styles.campoTitulo}>
                                    INCONSISTENCIAS
                                </Text>
                            </View>
                            <View>
                                <Text style={{whiteSpace: "pre-wrap"}}>{pos.issues}</Text>
                            </View>
                        </View>
                    }
                    { pos.closedRegisters.length > 0 &&
                    <View style={{ marginTop: 20 }}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Cajas cerradas: </Text>
                        </View>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTituloRegister}>CAJA</Text>
                            </View>
                            {
                                pos.closedRegisters.map(
                                    register => {
                                        return(
                                            <View key={register.caja} style={styles.tr}>
                                                <Text style={styles.tablaCampoRegister}>{ register.caja }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                        </View>
                    </View>
                    }
                    { ((pos.adjust.kit !== null) && (pos.adjust.kit !== undefined) && (pos.adjust.kit.length > 0)) &&
                    <View style={{ marginTop: 20 }}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>AJUSTE DE KITS SIN COMPONENTE: </Text>
                        </View>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTituloAjuste}>CODIGO ITEM</Text>
                                <Text style={styles.tablaCampoTituloAjuste}>CANTIDAD AJUSTADA</Text>
                                <Text style={styles.tablaCampoTituloAjuste}>COSTO UNITARIO</Text>
                            </View>
                            {
                                pos.adjust.kit.map(
                                    ajt => {
                                        return(
                                            <View key={ajt.idItem} style={styles.tr}>
                                                <Text style={styles.tablaCampoAjuste}>{ ajt.idItem }</Text>
                                                <Text style={styles.tablaCampoAjuste}>{ ajt.cantidad }</Text>
                                                <Text style={styles.tablaCampoAjuste}>{ ajt.costo }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                        </View>
                    </View>
                    }
                    { ((pos.adjust.cantidad !== null) && (pos.adjust.cantidad !== undefined) && (pos.adjust.cantidad.length > 0)) &&
                    <View style={{ marginTop: 20 }}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>AJUSTE DE CANTIDAD NEGATIVA: </Text>
                        </View>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTituloAjuste}>CODIGO ITEM</Text>
                                <Text style={styles.tablaCampoTituloAjuste}>CANTIDAD AJUSTADA</Text>
                                <Text style={styles.tablaCampoTituloAjuste}>COSTO UNITARIO</Text>
                            </View>
                            {
                                pos.adjust.cantidad.map(
                                    ajt => {
                                        return(
                                            <View key={ajt.idItem} style={styles.tr}>
                                                <Text style={styles.tablaCampoAjuste}>{ ajt.idItem }</Text>
                                                <Text style={styles.tablaCampoAjuste}>{ ajt.cantidad }</Text>
                                                <Text style={styles.tablaCampoAjuste}>{ ajt.costo }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                        </View>
                    </View>
                    }
                    { ((pos.adjust.costo !== null) && (pos.adjust.costo !== undefined) && (pos.adjust.costo.length > 0)) &&
                    <View style={{ marginTop: 20 }}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>AJUSTE DE COSTO CERO: </Text>
                        </View>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTituloAjuste}>CODIGO ITEM</Text>
                                <Text style={styles.tablaCampoTituloAjuste}>CANTIDAD AJUSTADA</Text>
                                <Text style={styles.tablaCampoTituloAjuste}>COSTO UNITARIO</Text>
                            </View>
                            {
                                pos.adjust.costo.map(
                                    ajt => {
                                        return(
                                            <View key={ajt.idItem} style={styles.tr}>
                                                <Text style={styles.tablaCampoAjuste}>{ ajt.idItem }</Text>
                                                <Text style={styles.tablaCampoAjuste}>{ ajt.cantidad }</Text>
                                                <Text style={styles.tablaCampoAjuste}>{ ajt.costo }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                        </View>
                    </View>
                    }
                </View>
            </View>
        </Page>
    )
}


const PdfView = (props) => {
    return(
        <Document>
            <Page size="LETTER" style={styles.pagina}>
                <View style={styles.header}>
                    <Text style={styles.title}>EasyAuto</Text>
                    <Text style={styles.subTitle}>INFORME DE PROCESO DE ACUMULACION - { props.log.company.name }</Text>
                </View>
                <View style={styles.bodyContainer}>
                    <View style={styles.encabezadoContainer}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>FECHA:</Text>
                            <Text>{ props.log.main.createAt }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>ESTADO: </Text>
                            <Text>{ props.log.main.state === 1 ? 'TERMINADO CORRECTAMENTE' : 'ERROR' }</Text>
                        </View>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTitulo}>NOMBRE</Text>
                                <Text style={styles.tablaCampoTitulo}>ESTADO</Text>
                                <Text style={styles.tablaCampoTitulo}>DETALLE</Text>
                                <Text style={styles.tablaCampoTitulo}>FECHA/HORA</Text>
                            </View>
                            {
                                props.log.main.pos.map(
                                    pos => {
                                        return(
                                            <View key={pos._id} style={styles.tr}>
                                                <Text style={styles.tablaCampo}>{ pos.host }</Text>
                                                <Text style={styles.tablaCampo}>{ pos.state === 1 ? 'TERMINADO CORRECTAMENTE' : pos.state === 2 ? 'SIN ACUMULACION' : 'ERROR EN LA ACUMULACION' }</Text>
                                                <Text style={styles.tablaCampo}>{ pos.message }</Text>
                                                <Text style={styles.tablaCampo}>{ pos.createAt }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                        </View>
                    </View>
                </View>
            </Page>
            {
                props.log.main.pos.map(
                    pos => {
                        if((pos.state === 1) || (pos.state === -1)){
                            return(
                                <PagePos pos={pos} />
                            );
                        }
                    }
                )
            }
        </Document>
    );
}

export default PdfView;

const styles = StyleSheet.create({
    header: {
        marginBottom: 20
    },
    title: {
        fontSize: 16,
        marginBottom: 10,
        fontWeight: 900
    },
    subTitle: {
        fontSize: 14,
        fontWeight: "bold" 
    },
    proveedorContainer: {
        fontSize: 10,
        marginBottom: 20
    },
    bodyContainer: {
        
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
        marginBottom: 10
    },
    campoTitulo: {
        fontSize: 12
    },
    tabla: {
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 10,
        overflow: "hidden"
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        width: 128,
        backgroundColor: 'black',
        color: 'white',
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampoTituloRegister: {
        width: 512,
        backgroundColor: 'black',
        color: 'white',
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampoTituloAjuste: {
        width: 171,
        backgroundColor: 'black',
        color: 'white',
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        width: 128,
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoRegister: {
        width: 512,
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoAjuste: {
        width: 171,
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        //width: 64,
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});


