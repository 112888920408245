const INITIAL_STATE = {
    mainLogId: '',
    company: { name: '' },
    posServer: { host: '', state: 0 }
};
 
export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "SET_MAIN_LOG_ID_SELECTED":
            return {...state, mainLogId: action.payload};
        case "SET_COMPANY_SELECTED":
            return {...state, company: action.payload};
        case "SET_POS_SERVER_SELECTED":
            return {...state, posServer: action.payload};
        case "CLEAN_DATA":
            return {...state, company: INITIAL_STATE.company, posServer: INITIAL_STATE.posServer};
                    
            default: return state;
    }
}