import { httpPost, httpGetError, httpGet } from '../http';

export const setMainLogIdAction = (mainLogId) => (dispatch, getState) => {
    console.log("SET MAIN LOG ID SELECTED");
    console.log(mainLogId)
    dispatch({
        type: 'SET_MAIN_LOG_ID_SELECTED',
        payload: mainLogId
    });
    
}

export const setCompanySelectedAction = (company) => (dispatch, getState) => {

    dispatch({
        type: 'SET_COMPANY_SELECTED',
        payload: company
    });
    
}

export const setPosServerSelectedAction = (posServer) => (dispatch, getState) => {

    dispatch({
        type: 'SET_POS_SERVER_SELECTED',
        payload: posServer
    });
    
}

export const updateSelectors = () => (dispatch, getState) => {
    try{
        const { session } = getState().SessionReducer;
        const { company } = getState().SelectorsReducer;
        const { posServer } = getState().SelectorsReducer;

        if((company._id) && (company.mainLogId)){
            httpGet("/log/user/company/id/" + company.mainLogId, session._id).then(
                resp => {
                    dispatch({
                        type: 'SET_COMPANY_SELECTED',
                        payload: resp.data
                    });
                }
            ).catch(
                error => {
                    console.log(error);
                }
            );
        }

        if (posServer._id){
            httpGet("/log/user/pos-server/id/" + posServer.posLogId, session._id).then(
                resp => {
                    dispatch({
                        type: 'SET_POS_SERVER_SELECTED',
                        payload: resp.data
                    });
                }
            ).catch(
                error => {
                    console.log(error);
                }
            );
        }
    } catch (error) {
        console.log(error);
        /*dispatch({ type: 'SET_COMPANIES_ERROR', payload: error })*/
    }
}
