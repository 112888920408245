import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { httpGet } from '../../http';
import Box from '../../components/box/Box';
import { connect } from 'react-redux';
import { CheckCircleTwoTone, CloseCircleTwoTone, MinusCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { formatDateMin, formatDate } from '../../misc/date';
import { EyeOutlined } from '@ant-design/icons';
import IconState from '../../components/IconState';
import IconState2 from '../../components/IconState2';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import * as LogActions from '../../actions/LogActions';
import * as PhasesActions from '../../actions/PhasesActions';
import * as SelectorsActions from '../../actions/SelectorsActions';
import LogReducer from '../../reducers/LogReducer';

const FaseBox = (props) => {
    const { fase } = props;
    return(
        <Container style={{borderWidth: 0, borderColor: "black", borderStyle: "solid"}} className="box-container">
            <Row className="box-row" style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginLeft: 0, width: "100%", display: "flex", alignItems: "center"}}>
                <Col className="box-col" xs={3}>
                    <IconState2 state={fase.state} />
                </Col>
                <Col>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginBottom: "5px", fontSize: "14px"}} className="box-row">
                        <Col className="box-col"><div className="box-title">{fase.phase}</div></Col>
                    </Row>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", fontSize: "14px"}} className="box-row">
                        <Col className="box-col">{formatDate(new Date(fase.createAt))}</Col>
                    </Row>
                </Col>
            </Row>
            
        </Container>
    );
}


const { getPhasesAction } = PhasesActions;
const { setPosServerSelectedAction, updateSelectors } = SelectorsActions;

const Fases = (props) => {
    
    const { getPhasesAction, setPosServerSelectedAction, updateSelectors } = props;
    const { company : companySelected } = props.SelectorsReducer;
    const { posServer : posServerSelected } = props.SelectorsReducer;
    const { data, error, errorMsg, loading } = props.PhasesReducer;
    
    useEffect(() => {
        sessionStorage.setItem('location', 'fases');
        updateSelectors();
        getPhasesAction();
    }, []);

    const goToPhase = (e, fase) => {
        e.preventDefault();
        props.history.push({ pathname: '/fase', state: { fase: fase, companySelected: companySelected, posServerSelected: posServerSelected } });
    }



    return(  
        <div>
            { error ?
                <Alert className='alert' variant='danger'>{errorMsg}</Alert>
            :
            !loading &&
            <div>
                <div className="page-title2">
                    EMPRESA: { companySelected.name } {<IconState state={companySelected.state} /> }
                </div>
                <div className="page-title2">
                    SERVIDOR POS: { posServerSelected.type === 1 ? "PDV" : posServerSelected.type === 3 ? "PDV SECUNDARIO" : "RESTAURANTE" } - { posServerSelected.co } - { posServerSelected.host } {<IconState state={posServerSelected.state} />}
                </div>

                <div className="pc-page">
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>NOMBRE</th>
                                <th>ESTADO</th>
                                <th>DETALLE</th>
                                <th>FECHA/HORA</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.map(
                            (fase, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{fase.phaseNumber}</td>
                                        <td>{fase.phase}</td>
                                        <td><IconState state={fase.state} /></td>
                                        <td>{fase.message}</td>
                                        <td>{formatDate(new Date(fase.createAt))}</td>
                                    </tr>
                                )
                            }
                        )}        
                        </tbody>
                    </table>
                    <br />
                    <br />
                    { ((posServerSelected.issues !== null) && (posServerSelected.issues !== undefined) && (posServerSelected.issues.length > 0)) &&
                    <div>
                        <div className="page-title2">
                            INCONSISTENCIAS
                        </div>
                        <Alert variant='danger'><div style={{whiteSpace: "pre-wrap"}}>{posServerSelected.issues}</div></Alert>
                        <br />
                        <br />
                    </div>
                    }
                    { ((posServerSelected.issues !== null) && (posServerSelected.issues !== undefined) && (posServerSelected.closedRegisters.length > 0)) &&
                    <div>
                        <div className="page-title2">
                            CAJAS CERRADAS
                        </div>
                        <table className="main-table">
                            <thead>
                                <tr>
                                    <th>CAJA</th>
                                </tr>
                            </thead>
                            <tbody>
                            {posServerSelected.closedRegisters.map(
                                (register, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{register.caja}</td>
                                        </tr>
                                    )
                                }
                            )}        
                            </tbody>
                        </table>
                        <br />
                        <br />
                    </div>
                    }
                    { ((posServerSelected.adjust !== null) && (posServerSelected.adjust !== undefined)) &&
                    <div>
                    { ((posServerSelected.adjust.kit !== null) && (posServerSelected.adjust.kit !== undefined) && (posServerSelected.adjust.kit.length > 0)) &&
                    <div>
                        <div className="page-title2">
                            AJUSTE DE NEGATIVOS - KIT SIN COMPONENTES
                        </div>
                        <table className="main-table">
                            <thead>
                                <tr>
                                    <th>CODIGO ITEM</th>
                                    <th>CANTIDAD</th>
                                    <th>COSTO UNITARIO</th>
                                </tr>
                            </thead>
                            <tbody>
                            {posServerSelected.adjust.kit.map(
                                (kit, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{kit.idItem}</td>
                                            <td>{kit.cantidad}</td>
                                            <td>{kit.costo}</td>
                                        </tr>
                                    )
                                }
                            )}        
                            </tbody>
                        </table>
                        <br />
                        <br />
                    </div>
                    }
                    { ((posServerSelected.adjust.cantidad !== null) && (posServerSelected.adjust.cantidad !== undefined) && (posServerSelected.adjust.cantidad.length > 0)) &&
                    <div>
                        <div className="page-title2">
                            AJUSTE DE NEGATIVOS - CANTIDAD NEGATIVA
                        </div>
                        <table className="main-table">
                            <thead>
                                <tr>
                                    <th>CODIGO ITEM</th>
                                    <th>CANTIDAD</th>
                                    <th>COSTO UNITARIO</th>
                                </tr>
                            </thead>
                            <tbody>
                            {posServerSelected.adjust.cantidad.map(
                                (kit, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{kit.idItem}</td>
                                            <td>{kit.cantidad}</td>
                                            <td>{kit.costo}</td>
                                        </tr>
                                    )
                                }
                            )}        
                            </tbody>
                        </table>
                        <br />
                        <br />
                    </div>
                    }
                    { ((posServerSelected.adjust.costo === null) && (posServerSelected.adjust.costo === undefined) && (posServerSelected.adjust.costo.length > 0)) &&
                    <div>
                        <div className="page-title2">
                            AJUSTE DE NEGATIVOS - COSTO CERO
                        </div>
                        <table className="main-table">
                            <thead>
                                <tr>
                                    <th>CODIGO ITEM</th>
                                    <th>CANTIDAD</th>
                                    <th>COSTO UNITARIO</th>
                                </tr>
                            </thead>
                            <tbody>
                            {posServerSelected.adjust.costo.map(
                                (kit, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{kit.idItem}</td>
                                            <td>{kit.cantidad}</td>
                                            <td>{kit.costo}</td>
                                        </tr>
                                    )
                                }
                            )}        
                            </tbody>
                        </table>
                        <br />
                        <br />
                    </div>
                    }
                    </div>
                    }
                </div>
                

                <div className="boxes">
                    {data.map(
                        (fase, index) => {
                            return(
                                <div key={index} className="">
                                    {
                                        fase.state === -1 ?
                                        <Link to={{ pathname: '', }} onClick={ (e) => { goToPhase(e, fase) } } >
                                            <FaseBox fase={fase} />
                                        </Link>
                                        :
                                        <FaseBox fase={fase} />
                                    }
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
            }
        </div>        
    );
}

const mapStateToProps = ({ SessionReducer, SelectorsReducer, CompaniesReducer, PosServersReducer, PhasesReducer }) => {
    return {
        SessionReducer,
        SelectorsReducer,
        CompaniesReducer,
        PosServersReducer,
        PhasesReducer
    }
};

const mapDispatchToProps = {
    getPhasesAction,
    setPosServerSelectedAction,
    updateSelectors
}

export default connect(mapStateToProps, mapDispatchToProps)(Fases);