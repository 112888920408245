import React, {useEffect, useState} from 'react';
import { httpGet, httpPost } from '../../http';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const Box = (props) => {
    const { pos } = props;
    return(
        <Container style={{borderWidth: 0, borderColor: "black", borderStyle: "solid"}} className="box-container">
            <Row className="box-row" style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginLeft: 0, width: "100%", display: "flex", alignItems: "center"}}>
                <Col>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginBottom: "5px", fontSize: "14px"}} className="box-row">
                        <Col className="box-col"><div className="box-title">{props.children}</div></Col>
                        <Col className="box-col"><div className="box-switch" ><Form.Check type="switch" id={props.id} label="" onChange={props.onChange} checked={props.checked} disabled={props.disabled} /></div></Col>
                    </Row>
                </Col>
            </Row>
            
        </Container>
    );
}

const ConfDetail = (props) => {

    const { session } = props.SessionReducer;
    const { pos } = props.history.location.state;

    const [ confDetail, setConfDetail ] = useState({
        data: {
            active: false,
            autoPosClose: false,
            autoThirdCreation: false
        },
        loading: false,
        error: false,
        errorMsg: ""
    });

    useEffect(() => {

        if (session.user.role > 1){
            props.history.push('/home');
            return 0;
        }

        getPosConf();
    }, []);

    const getPosConf = async () => {
        const resp = await httpGet('/pos/id/' + pos._id, session._id);
        if(!resp){
            return 0;
        }

        setConfDetail({
            ...confDetail,
            data: resp.data
        });
    }

    const changeOption = async (option, value) => {
        
        let conf = {
            ...confDetail.data
        };
        
        if (option === 'ACUM'){
            conf.active = value
        } else if (option === 'CLOSE'){
            conf.autoPosClose = value;
        } else if (option === 'THIRDS'){
            conf.autoThirdCreation = value;
        }

        
        const resp = await httpPost('/pos', conf, session._id);
        setConfDetail({
            ...confDetail,
            data: resp.data
        });

    }

    return(
        <div>
            <div>
                <div className='header'>
                    <div className="page-title2">
                        CONFIGURACIÓN DE SERVIDOR PDV - { pos.co } { pos.host }:
                    </div>
                </div>
                <div className="boxes1">
                    <Box checked={confDetail.data.active} onChange={() => { changeOption('ACUM', !confDetail.data.active); }} id="acum">Acumular servidor PDV</Box>
                    <Box checked={confDetail.data.autoPosClose} onChange={() => { changeOption('CLOSE', !confDetail.data.autoPosClose); }} id="close" disabled={!confDetail.data.active}>Cerrar cajas</Box>
                    <Box checked={confDetail.data.autoThirdCreation} onChange={() => { changeOption('THIRDS', !confDetail.data.autoThirdCreation); }} id="thirds" disabled={!confDetail.data.active}>Crear terceros</Box>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ SessionReducer }) => {
    return {
        SessionReducer
    }
};

export default connect(mapStateToProps)(ConfDetail);