const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false,
    errorMsg: ''
};
 
export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "SET_POSSERVERS_DATA":
            return {...state, loading: false, error: false, errorMsg: '', data: action.payload};
        case "CLEAN_POSSERVERS_DATA":
            return {...state, loading: false, error: false, errorMsg: '', data: []};
        case "SET_POSSERVERS_LOADING":
            return {...state, loading: true, error: false, errorMsg: '' };
        case "SET_POSSERVERS_ERROR":
            return {...state, loading: false, error: true, errorMsg: action.payload };
                    
            default: return state;
    }
}