const INITIAL_STATE = {
    isVisibleBackButton: false
};
 
export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "SET_TRUE_SHOW_BACK_BUTTON":
            return {...state, isVisibleBackButton: true}
        case "SET_FALSE_SHOW_BACK_BUTTON":
            return {...state, isVisibleBackButton: false}
        default: return state;
    }
}