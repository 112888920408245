import React, { useEffect, useState } from 'react';
import { Navbar, Spinner, Button } from 'react-bootstrap';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as SessionActions from '../../actions/SessionActions';
import * as LogActions from '../../actions/LogActions';
import * as CompaniesActions from '../../actions/CompaniesActions';
import * as PosServersActions from '../../actions/PosServersActions';
import * as PhasesActions from '../../actions/PhasesActions';
import * as SelectorsActions from '../../actions/SelectorsActions';
import * as GlobalActions from '../../actions/GlobalActions';
import Home from './Home';
import './HomeLayout.css';
import PosServers from '../posServers/PosServers';
import Fases from '../fases/Fases';
import Fase from '../fase/Fase';
import Pdf from '../pdf/Pdf';
import { HomeOutlined, SettingOutlined, UserOutlined, WarningTwoTone, ExclamationCircleTwoTone, LeftOutlined, MenuOutlined } from '@ant-design/icons';
import { OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { io } from 'socket.io-client';
import { getURL } from '../../http';
import { ONESIGNAL_APP_ID } from '../../config';
import SessionReducer from '../../reducers/SessionReducer';
import Conf from '../conf/Conf';
import ConfPos from '../conf/ConfPos';
import ConfDetail from '../conf/ConfDetail';
import Profile from '../profile/Profile';

const Nav = (props) => {
    return(
        <Navbar className="nav" fixed="top" bg="dark" variant="dark" collapseOnSelect expand="md"> 
            <Navbar.Brand className="brand-title" href="#home">EasyAuto</Navbar.Brand>
            <Link to={{ pathname: '' }} onClick={props.onToggle}>
                <div className="menu-button">
                    <MenuOutlined />
                </div>
            </Link>
            <Navbar.Text className="menu-user-label">Hola  { props.session.user.name } <a href="#" onClick={props.onClick}>Salir</a></Navbar.Text>
        </Navbar>
    );
}

const Menu = (props) => {
    return(
        <div className={props.menu.homeWrapperClass}>
            <div className={props.menu.menuClass}>
                <div className='menu-logo-wrapper'>
                </div>
                <OverlayTrigger placement='top' overlay={
                    <Tooltip id='error' className="tooltip">
                        Inicio
                    </Tooltip>
                }>
                    <div className='menu-option-wrapper' onClick={() => { props.onClick('HOME') }}>
                        <HomeOutlined className={props.menu.menuIconHomeClass} onMouseOver={() => {props.onMouseOver('HOME')}} onMouseLeave={() => {props.onMouseLeave('HOME')}}  />
                        <div className="menu-option-label">Inicio</div>
                    </div>
                </OverlayTrigger>
                { props.role < 2 &&
                <OverlayTrigger placement='top' overlay={
                        <Tooltip id='error' className="tooltip">
                            Configuración
                        </Tooltip>
                }>
                    <div className='menu-option-wrapper' onClick={() => { props.onClick('CONFIG') }}>
                            <SettingOutlined className={props.menu.menuIconConfigClass} onMouseOver={() => {props.onMouseOver('CONFIG')}} onMouseLeave={() => {props.onMouseLeave('CONFIG')}}  />
                            <div className="menu-option-label">Configuración</div>
                    </div>
                </OverlayTrigger>
                }
                <OverlayTrigger placement='top' overlay={
                        <Tooltip id='error' className="tooltip">
                            Perfil
                        </Tooltip>
                }>
                    <div className='menu-option-wrapper' onClick={() => { props.onClick('PROFILE') }}>
                            <UserOutlined className={props.menu.menuIconProfileClass} onMouseOver={() => {props.onMouseOver('PROFILE')}} onMouseLeave={() => {props.onMouseLeave('PROFILE')}}  />
                            <div className="menu-option-label">Perfil</div>
                    </div>
                </OverlayTrigger>
                {/*
                <OverlayTrigger placement='top' overlay={
                        <Tooltip id='error'>
                            Historicos
                        </Tooltip>
                }>
                    <div className='menu-option-wrapper'>
                            <FileSearchOutlined className={props.menu.menuIconHistoryClass} onMouseOver={() => {props.onMouseOver('HISTORY')}} onMouseLeave={() => {props.onMouseLeave('HISTORY')}} />
                            <div className="menu-option-label">Historico</div>
                    </div>
                </OverlayTrigger>
                */}
            </div>
        </div>
    );
}

const AlertDue = (props) => {
    return(
        <Alert className='alert' variant={props.variant}>{props.state === 1 ? <WarningTwoTone style={{ fontSize: 24 }} twoToneColor="#856404" /> : <ExclamationCircleTwoTone style={{ fontSize: 24 }} twoToneColor="#721c24" /> } {props.children}</Alert>
    );
}

const AlertPushNotification = (props) => {
    return(
        <Alert className='alert' variant={props.variant}>
            <div>
                Las notificaciones se encuentran desactivadas, por favor active las notificaciones para recibir alertas del proceso de acumulación.
            </div>
            <div style={{ marginTop: "20px" }}>
                <Button onClick={props.onClick}>Activar</Button>
            </div>
        </Alert>
    );
}


const { valSession, closeSession } = SessionActions;
const { getCompaniesAction, cleanCompaniesAction } = CompaniesActions;
const { getPosServersAction } = PosServersActions;
const { getPhasesAction } = PhasesActions;
const { setMainLogIdAction, updateSelectors } = SelectorsActions;
const { showBackButton, hideBackButton } = GlobalActions;

const HomeLayout = (props) => {

    const { valSession, getCompaniesAction, getPosServersAction, getPhasesAction, closeSessionm, cleanCompaniesAction, setMainLogIdAction, updateSelectors, showBackButton, hideBackButton } = props;    
    const { session } = props.SessionReducer;
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;
    const { mainLogId } = props.SelectorsReducer;
    const { data : companies } = props.CompaniesReducer;
    const { isVisibleBackButton } = props.GlobalReducer;
    
    const [ menu, setMenu ] = useState({
        toggle: false,
        menuClass: 'menu',
        homeWrapperClass: 'home-wrapper',
        homeTitle: 'Proveedores APP',
        menuIconHomeClass: 'menu-option-icon',
        menuIconConfigClass: 'menu-option-icon',
        menuIconHistoryClass: 'menu-option-icon',
        menuIconProfileClass: 'menu-option-icon',
        menuIconHomeTextClass: 'menu-option-text',
        menuIconConfigTextClass: 'menu-option-text',
        menuIconHistoryTextClass: 'menu-option-text',
        menuIconProfileTextClass: 'menu-option-text',
    });

    const [ alertPush, setAlertPush ] = useState(false);
    const [ isSession, setIsSession ] = useState(false);

    useEffect(() => {
        valSession(props.history, 2);

        if(localStorage.getItem('session-token')){
            setIsSession(true);
        }

        console.log(props.history.length);

        OneSignal.push(["getNotificationPermission", function(permission) {
            console.log("Site Notification Permission:", permission);
            if(permission == 'granted'){
                console.log("GRANTED");
                setAlertPush(false);
            } else {
                setAlertPush(true);
            }
            // (Output) Site Notification Permission: default
        }]);

        try{

            OneSignal.push(() => {
    
                OneSignal.on('subscriptionChange', function (isSubscribed) {
                    console.log("The user's subscription state is now:", isSubscribed);
                    if(isSubscribed){
                        setAlertPush(false);
                    } else {
                        setAlertPush(true);
                    }
                });
    
                /*OneSignal.isPushNotificationsEnabled(function(isEnabled) {
                    if (isEnabled)
                      console.log("Push notifications are enabled!");
                    else
                      console.log("Push notifications are not enabled yet.");
                      setAlertPush(true);
                });*/
    
                //console.log("OneSignal:");
                //console.log(OneSignal);
                OneSignal.setExternalUserId(localStorage.getItem('session-token'));
                OneSignal.init({
                    appId: ONESIGNAL_APP_ID,
                });
            });
        } catch (error) {

        } 
        
        
        /*const socket = io(getURL(), { autoConnect: false, transports: ['websocket']});*/
        /*const socket = io("http://192.168.1.26:8080", { transports: ['websocket']});*/

        const socket = io(getURL(), { autoConnect: false });
        socket.auth = { sessionId: session._id };
        socket.connect();

        socket.emit('HELLO', {
            from: localStorage.getItem('session-token'),
        });

        socket.on('RESPONSE', data => {
            
        });

        socket.on('REFRESH', data => {
            
            console.log('REFRESH DATA');
            //console.log(sessionStorage.getItem('mainLogId') + " - " + data.mainLogId);

            const location = sessionStorage.getItem('location');

            if(location){
                if(location === 'home'){
                    getCompaniesAction();    
                }

                if(location === 'pos'){
                    if(sessionStorage.getItem('currentCompanyId') === data.companyId){
                        updateSelectors();
                        getPosServersAction();
                        getCompaniesAction();
                    }
                }

                if(location === 'fases'){
                    if(sessionStorage.getItem('currentCompanyId') === data.companyId){
                        updateSelectors();
                        getPosServersAction();
                        getPhasesAction();
                        getCompaniesAction();
                    }
                }
            }
            
            
            
            
            /*if(sessionStorage.getItem('mainLogId') !== data.mainLogId){
                console.log('NEW MAIN LOG ID');
                sessionStorage.setItem('mainLogId', data.mainLogId);
                //setMainLogIdAction(data.mainLogId);
                cleanCompaniesAction();
                props.history.push('/');
            } else {
                console.log('REFRESH');
                updateSelectors();
                getCompaniesAction();
                getPosServersAction();
                getPhasesAction();
                //scrollToBottom();
            }*/
        });

        window.addEventListener("pageshow", handlePageShow);

        getCompaniesAction();
        //scrollToBottom();

        return(() => {
            socket.disconnect();
            window.removeEventListener("pageshow", handlePageShow);
        });

    }, []);

    const handlePageShow = (e) => {
        console.log("HOLA!!!");
        getCompaniesAction();
    }

    useEffect(() => {
        getCompaniesAction();
    }, [props.SessionReducer.session]);

    const onToggle = (e) => {
        e.preventDefault();
        onMenu();
    }

    const scrollToBottom = () => {
        console.log("SCROLL");
        window.scrollTo({ 
            top: document.documentElement.scrollHeight,
            behavior: 'auto'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
          }); 
    }

    const onMenu = () => {
        if (window.innerWidth <= 850){
            let toggle = !menu.toggle;
            let menuClass = toggle ? 'menu-active' : 'menu';
            let homeWrapperClass = toggle ? 'home-wrapper-active' : 'home-wrapper';
            let homeTitle = toggle ? '' : 'Proveedores APP';
            setMenu({
                ...menu,
                toggle: toggle,
                menuClass: menuClass,
                homeWrapperClass: homeWrapperClass,
                homeTitle: homeTitle
            });
        }
    }

    const handleOnMouserOver = (option) => {
        if (option === 'HOME'){
            setMenu({
                ...menu,
                menuIconHomeClass: 'menu-option-icon-hover',
                menuIconHomeTextClass: 'menu-option-text-hover',
            });
        }

        if (option === 'CONFIG'){
            setMenu({
                ...menu,
                menuIconConfigClass: 'menu-option-icon-hover',
                menuIconConfigTextClass: 'menu-option-text-hover'
            });
        }

        if (option === 'PROFILE'){
            setMenu({
                ...menu,
                menuIconProfileClass: 'menu-option-icon-hover',
                menuIconProfileTextClass: 'menu-option-text-hover'
            });
        }

        if (option === 'HISTORY'){
            setMenu({
                ...menu,
                menuIconHistoryClass: 'menu-option-icon-hover',
                menuIconHistoryTextClass: 'menu-option-text-hover'
            });
        }
    }


    const handleOnMouserLeave = (option) => {
        if (option === 'HOME'){
            setMenu({
                ...menu,
                menuIconHomeClass: 'menu-option-icon',
                menuIconHomeTextClass: 'menu-option-text',
            });
        }

        if (option === 'CONFIG'){
            setMenu({
                ...menu,
                menuIconConfigClass: 'menu-option-icon',
                menuIconConfigTextClass: 'menu-option-text'
            });
        }
        
        if (option === 'PROFILE'){
            setMenu({
                ...menu,
                menuIconProfileClass: 'menu-option-icon',
                menuIconProfileTextClass: 'menu-option-text'
            });
        }

        if (option === 'HISTORY'){
            setMenu({
                ...menu,
                menuIconHistoryClass: 'menu-option-icon',
                menuIconHistoryTextClass: 'menu-option-text',
            });
        }
    }

    const goToPage = (option) => {
        onMenu();
        if (option === 'HOME'){
            hideBackButton();
            props.history.replace("/");
        } else if (option === 'CONFIG'){
            showBackButton();
            props.history.push('/conf');
        } else if (option === 'PROFILE'){
            showBackButton();
            props.history.push('/profile');
        }
    }

    const handleExit = () => {

        localStorage.removeItem('session-token')
        closeSession();
        props.history.push('/login');
    }

    const showActivatePush = () => {
        OneSignal.push(function() {
            OneSignal.showNativePrompt();
        });
    }

    const handleBackClick = () => {
        props.history.goBack();
    }

    return(
        <>
        { isSession &&
            <div>
                <Nav session={props.SessionReducer.session} onClick={handleExit} onToggle={onToggle} />
                <Menu menu={ menu } onMouseOver={ handleOnMouserOver } onMouseLeave={ handleOnMouserLeave } onClick={ goToPage } role={props.SessionReducer.session.user.role} />
                
                <div className='home-main page-container'>
                    { isVisibleBackButton &&
                        <div>
                            <LeftOutlined className="back-button" onClick={handleBackClick} />
                        </div>
                    }
                    { alertPush &&
                        <AlertPushNotification variant="warning" onClick={showActivatePush} />
                    }
                    {
                    <div>
                        {companies.map(
                            (company, index) => {
                                if ((company.dueState > 0) && (company.active)){
                                    return (
                                        <AlertDue key={index} variant={company.dueState === 1 ? "warning" : "danger"} state={company.dueState}>{company.dueState === 1 ? "ATENCIÓN: " + company.name + " quedan " + company.dueDays + " dias de servicio, comuniquese son nosotros para renovar el servicio." : "ATENCIÓN: " + company.name + " el servicio se encuentra suspendido, comuniquese con nosotros para renovar el servicio."}</AlertDue>
                                    );
                                }
                                //return(<div key={index}></div>);
                            }
                        )}
                    </div>
                    }
                    <Switch>
                        <Route path="/pos-servers" render={() => (<PosServers history={props.history} />)} />
                        <Route path="/fases" render={() => (<Fases history={props.history} />)} />
                        <Route path="/fase" render={() => (<Fase history={props.history} />)} />
                        <Route path="/pdf" render={() => (<Pdf history={props.history} />)} />
                        <Route path="/conf" render={() => (<Conf history={props.history} />)} />
                        <Route path="/conf-pos" render={() => (<ConfPos history={props.history} />)} />
                        <Route path="/conf-detail" render={() => (<ConfDetail history={props.history} />)} />
                        <Route path="/profile" render={() => (<Profile history={props.history} />)} />
                        <Route path="/" render={() => (<Home history={props.history} refresh={getCompaniesAction} />)} />
                    </Switch>
                </div>            
            </div>
        }
        </>
    );
}

const mapStateToProps = ({ SessionReducer, SelectorsReducer, CompaniesReducer, GlobalReducer }) => {
    return {
        SessionReducer,
        SelectorsReducer,
        CompaniesReducer,
        GlobalReducer
    };
};
const mapDispatchToProps = {
    valSession,
    getCompaniesAction,
    getPosServersAction,
    getPhasesAction,
    closeSession,
    setMainLogIdAction,
    cleanCompaniesAction,
    updateSelectors,
    showBackButton,
    hideBackButton
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeLayout);
