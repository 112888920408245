const INITIAL_STATE = {
    session: {
        _id: '',
        user: {},
        createAt: new Date(),
    },
    sessionLoading: false,
    sessionError: {
        errorCode: 0,
        error: false,
        errorMsg: ''
    }
};
 
export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "SET_SESSION":
            return {...state, sessionLoading: false, sessionError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}, session: action.payload};

        case "CLEAN_SESSION":
            return INITIAL_STATE;
        
        case "SESSION_LOADING":
            return {...state, sessionLoading: true, sessionError: { ...state.error, errorCode: 0, error: false, errorMsg: ''} };

        case "SESSION_ERROR":
            return {...state, sessionLoading: false, sessionError: action.payload };
            
            default: return state;
    }
}