import { combineReducers } from 'redux';
import SessionReducer from './SessionReducer';
import CompaniesReducer from './CompaniesReducer';
import LogReducer from './LogReducer';
import SelectorsReducer from './SelectorsReducer';
import PosServersReducer from './PosServersReducer';
import PhasesReducer from './PhasesReducer';
import PdfDataReducer from './PdfDataReducer';
import GlobalReducer from './GlobalReducer';

export default combineReducers({
    SessionReducer,
    CompaniesReducer,
    LogReducer,
    SelectorsReducer,
    PosServersReducer,
    PhasesReducer,
    PdfDataReducer,
    GlobalReducer
});