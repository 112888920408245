import React from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import IconState from '../../components/IconState';
import './Fase.css'; 

const FaseView = (props) => {
    return (
        <div>
            <div className="page-title2">
                EMPRESA: { props.companySelected.name } {<IconState state={props.companySelected.state} /> }
            </div>
            <div className="page-title2">
                SERVIDOR POS: { props.posServerSelected.type === 1 ? "PDV" : props.posServerSelected.type === 3 ? "PDV SECUNDARIO" : "RESTAURANTE" } - { props.posServerSelected.co } - { props.posServerSelected.host } {<IconState state={props.posServerSelected.state} />}
            </div>
            <div className="page-title2">
                FASE: { props.fase.phase } {<IconState state={props.fase.state} />}
            </div>
            <Alert className='alert' variant='danger'>{props.fase.message}</Alert>
            { ((props.posServerSelected.issues !== null) && (props.posServerSelected.issues !== undefined) && (props.posServerSelected.issues.length > 0)) &&
                <div>
                    <div className="page-title2">
                        INCONSISTENCIAS
                    </div>
                    <Alert variant='danger'><div style={{whiteSpace: "pre-wrap", overflowX: "auto"}}>{props.posServerSelected.issues}</div></Alert>
                    <br />
                    <br />
                </div>
            }
        </div>
    );
}

export default FaseView;