const INITIAL_STATE = {
    log: {}
};
 
export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "SET_PDFDATA":
            return {...state, log: action.payload};
                                
            default: return state;
    }
}