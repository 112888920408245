import React, { useState, useEffect } from 'react';
import './Login.css'
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import * as SessionActions from '../../actions/SessionActions';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

const Login = (props) => {

    const { getSession } = props;
    const { valSession } = props;
    const { sessionError } = props;
    
    useEffect(() => {
        valSession(props.history, 1);
    }, []);

    const [ login, setLogin ] = useState({
        email: '',
        password: ''
    }); 
    
    const handleChange = (e) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        getSession(login, props.history);
    }

    return(
        <div className='login-container'>
            <Row>
                <Col className='md6'>
                    <div className='login-box'>
                        { sessionError.error &&
                            <Alert className='alert' variant='danger'>{sessionError.errorMsg}</Alert>
                        }
                        <div className='login-title'>EasyAuto</div>
                        <Form className='form-login'>
                            <Form.Group>
                                <Form.Control className='text-field' type='email' name='email' placeholder='Digite su correo electronico' onChange={handleChange} value={login.email} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control className='text-field' type='password' name='password' placeholder='Digite su contraseña' onChange={handleChange} value={login.password} />
                            </Form.Group>
                            <Button variant="primary" type="submit" onClick={handleSubmit} >
                                Entrar
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

const mapStateToProps = ({ SessionReducer }) => SessionReducer;

export default connect(mapStateToProps, SessionActions)(Login);