import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './pages/login/Login';
import HomeLayout from './pages/home/HomeLayout';



function App() {
  return (
    <BrowserRouter>
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/" component={HomeLayout} />
        </Switch>
    </BrowserRouter>
  );
}

export default App;
