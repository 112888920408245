export const formatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let hday = "a.m."

    if(hour > 11){
        hday = "p.m."
    }

    let sDate = year + "-";
    if(month < 9){
        sDate = sDate + "0";
    }
    sDate = sDate + month + "-";
    if(day < 9){
        sDate = sDate + "0";
    }
    sDate = sDate + day + " ";
    
    if(hour > 12){
        if((hour - 12) < 10){
            sDate = sDate + "0";
        }
        sDate = sDate + (hour - 12) + ":";
    } else {
        if((hour) < 10){
            sDate = sDate + "0";
        }
        sDate = sDate + (hour) + ":";
    }

    if(minutes < 10){
        sDate = sDate + "0";
    }
    sDate = sDate + minutes + ":";
    if(seconds < 10){
        sDate = sDate + "0";
    }
    sDate = sDate + seconds + " " + hday;
    

    return sDate;
}

export const formatDateMin = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let sDate = year + "/";
    if(month < 9){
        sDate = sDate + "0";
    }
    sDate = sDate + month + "/";
    if(day < 9){
        sDate = sDate + "0";
    }
    sDate = sDate + day + " ";

    return sDate;
}

export const getDays = (date1, date2) => {
    var difference= Math.abs(date1-date2);
    let days = difference/(1000 * 3600 * 24)

    return days;
}
