import { httpPost, httpGetError, httpGet } from '../http';

export const getPhasesAction = () => (dispatch, getState) => { 
    try{
        const { posServer : posServerSelected } = getState().SelectorsReducer;
        const { session } = getState().SessionReducer;

        if(posServerSelected._id){
            httpGet("/log/user/company/posservers/phases/" + posServerSelected.posLogId, session._id).then(
                resp => {
                    
                    if(resp.data){
    
                        dispatch({
                            type: 'SET_PHASES_DATA',
                            payload: resp.data
                        });
                    }
                    
                }
            ).catch(
                error => {
                    let payloadError = httpGetError(error);
                    
                    dispatch({
                        type: "SET_PHASES_ERROR",
                        payload: payloadError.errorMsg
                    });
                }
            );
        } else {
            dispatch({
                type: "CLEAN_PHASES_DATA",
            });
        }

        
    } catch (error) {
        console.log(error);
        dispatch({ type: 'SET_PHASES_ERROR', payload: error })
    }
}

export const setPhasesLoadingAction = () => (dispatch, getState) => {
        
    try{

        dispatch({
            type: "SET_PHASES_LOADING",
        });

    } catch (error) {
        console.log(error);
        dispatch({ type: 'SET_PHASES_ERROR', payload: error })
    }
}

export const cleanPhasesAction = () => (dispatch, getState) => {
        
    try{

        dispatch({
            type: "CLEAN_PHASES_DATA",
        });

    } catch (error) {
        console.log(error);
        dispatch({ type: 'SET_PHASES_ERROR', payload: error })
    }
}
