/*const INITIAL_STATE = {    
    logs: [],
    log: {
        log: {
            _id: '',
            createAt: new Date()
        }
    },
    companies: [],
    company: {
        _id: '',
        name: ''
    },
    posServers: [],
    pos: {
        posServerId: '',
        name: '',
        message: '',
        state: -1,
        createAt: ''
    },
    fases: [],
    fase: {
        faseName: '',
        createAt: '',
        state: 0,
        message: '',
        inconsistency: ''
    },
    loading: false,
    error: {
        errorCode: 0,
        error: false,
        errorMsg: ''
    }
};*/

const INITIAL_STATE = {    
    companies: [],
    company: {
        _id: '',
        name: '',
        state: 0,
        log: {
            _id: '',
            mainId: '',
            message: '',
            state: 0,
            createAt: new Date(),
            posServers: []
        }
    },
    posServers: [],
    posServer: {
        posServerId: '',
        name: '',
        message: '',
        createAt: new Date(),
        state: 0,
        fases: []
    },
    fases: [],
    fase: {
        _id: '',
        faseNumber: '',
        fase: '',
        createAt: new Date(),
        state: 0,
        message: '',
        inconsistency: ''
    },

    logId: '',
    posServerId: '',
    faseId: '',
    
    logLoading: false,
    logError: {
        errorCode: 0,
        error: false,
        errorMsg: ''
    }
};
 
export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        
        case "SET_LOGS":
            return {...state, logLoading: false, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}, logs: action.payload};

        case "SET_LOG":
            return {...state, logLoading: false, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}, log: action.payload};            
        
        case "SET_COMPANIES":
            return {...state, logLoading: false, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}, companies: action.payload};            

        case "SET_COMPANY":
            return {...state, logLoading: false, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}, company: action.payload};            
            
        case "SET_POS_SERVERS":
            return {...state, logLoading: false, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}, posServers: action.payload};

        case "SET_POS":
            return {...state, logLoading: false, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}, pos: action.payload};            

        case "SET_FASES":
            return {...state, logLoading: false, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}, fases: action.payload};

        case "SET_FASE":
            return {...state, logLoading: false, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}, fase: action.payload};

        case "LOG_LOADING":
            return {...state, logLoading: true, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}};
        
        case "LOG_STOP_LOADING":
            return {...state, logLoading: false, logError: { ...state.error, errorCode: 0, error: false, errorMsg: ''}};

        case "LOG_ERROR":
            return {...state, logLoading: false, logError: action.payload};
                    
            default: return state;
    }
}