import React from 'react';
import { ReloadOutlined, EyeOutlined, CheckCircleTwoTone, CloseCircleTwoTone, MinusCircleTwoTone, LoadingOutlined, PauseCircleTwoTone, WarningTwoTone } from '@ant-design/icons';

const IconState = (props) => {
    return(
        <div className="icon-state-container">
            { 
                props.state === 0 ? <LoadingOutlined spin='true' style={{ fontSize: 'x-large' }} /> 
                    : props.state === 1 ? <CheckCircleTwoTone style={{ fontSize: 'x-large' }} twoToneColor="#52c41a" />
                        : props.state === 2 ? <MinusCircleTwoTone style={{ fontSize: 'x-large' }} twoToneColor="#a6a6a6" />
                            : props.state === -1 ? <CloseCircleTwoTone style={{ fontSize: 'x-large' }} twoToneColor="#ff0000" />
                                : props.state === 3 ? <PauseCircleTwoTone style={{ fontSize: 'x-large' }} />
                                    : <WarningTwoTone style={{ fontSize: 'x-large' }} twoToneColor="#ff9933" />
            }
        </div>
    );
}

export default IconState;