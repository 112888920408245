import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF, { PDFDownloadLink, PDFViewer, PDFRenderer } from '@react-pdf/renderer/dist/react-pdf.es';
import { connect } from 'react-redux';
import PdfView from '../../components/pdfView/PdfView';

const Pdf = (props) => {

    const { log } = props.history.location.state;

    return(
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <PdfView log={log} />
        </PDFViewer>
    );
}

const styles = StyleSheet.create({
    header: {
        marginBottom: 20
    },
    title: {
        fontSize: 16,
        marginBottom: 10,
        fontWeight: 900
    },
    subTitle: {
        fontSize: 14,
        fontWeight: "bold" 
    },
    proveedorContainer: {
        fontSize: 10,
        marginBottom: 20
    },
    bodyContainer: {
        
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
        marginBottom: 10
    },
    campoTitulo: {
        fontSize: 12
    },
    tabla: {
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 10,
        overflow: "hidden"
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        width: 128,
        backgroundColor: 'black',
        color: 'white',
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampoTituloRegister: {
        width: 512,
        backgroundColor: 'black',
        color: 'white',
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampoTituloAjuste: {
        width: 171,
        backgroundColor: 'black',
        color: 'white',
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        width: 128,
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoRegister: {
        width: 512,
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoAjuste: {
        width: 171,
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        //width: 64,
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

const mapStateToProps = ({ PdfDataReducer }) => {
    return {
        PdfDataReducer
    };
};

export default connect(mapStateToProps)(Pdf);

