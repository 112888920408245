import { httpPost, httpGetError, httpGet } from '../http';

export const getPosServersAction = () => (dispatch, getState) => {
    try{
        const { company : companySelected } = getState().SelectorsReducer;
        const { session } = getState().SessionReducer;
        if((companySelected._id) && (companySelected.mainLogId)){

            if(companySelected._id){        
                if(companySelected){
                    httpGet("/log/user/company/posservers/" + companySelected.mainLogId, session._id).then(
                        resp => {
                            
                            if(resp.data){
            
                                dispatch({
                                    type: 'SET_POSSERVERS_DATA',
                                    payload: resp.data
                                });
                            }
                            
                        }
                    ).catch(
                        error => {
                            let payloadError = httpGetError(error);
                            
                            dispatch({
                                type: "SET_POSSERVERS_ERROR",
                                payload: payloadError.errorMsg
                            });
                        }
                    );
                
                } else {
                    dispatch({
                        type: "SET_POSSERVERS_ERROR",
                        payload: 'Registro no econtrador.'
                    });
                }
            }
        } else {
            dispatch({
                type: 'CLEAN_POSSERVERS_DATA',
            });
        }
        
    } catch (error) {
        console.log(error);
        dispatch({ type: 'SET_POSSERVERS_ERROR', payload: error })
    }
}

export const setPosServersLoadingAction = () => (dispatch, getState) => {
        
    try{
       
        dispatch({
            type: "SET_POSSERVERS_LOADING",
        });
        
    } catch (error) {
        console.log(error);
        dispatch({ type: 'SET_POSSERVERS_ERROR', payload: error })
    }
}

export const cleanPosServersAction = () => (dispatch, getState) => {
        
    try{
       
        dispatch({
            type: "CLEAN_POSSERVERS_DATA",
        });
        
    } catch (error) {
        console.log(error);
        dispatch({ type: 'SET_POSSERVERS_ERROR', payload: error })
    }
}