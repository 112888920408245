import { httpPost, httpGetError, httpGet } from '../http';

export const getCompaniesAction = () => (dispatch, getState) => {
        
    try{
        const { session } = getState().SessionReducer;
        
        dispatch({
            type: "SET_COMPANIES_LOADING",
        });

        httpGet("/log/user/companies/" + session.user._id, session._id).then(
            resp => {
                
                if(resp.data){
                    dispatch({
                        type: 'SET_COMPANIES_DATA',
                        payload: resp.data
                    });
                }
                
            }
        ).catch(
            error => {
                let payloadError = httpGetError(error);
                
                dispatch({
                    type: "SET_COMPANIES_ERROR",
                    payload: payloadError.errorMsg
                });
            }
        );
    } catch (error) {
        console.log(error);
        dispatch({ type: 'SET_COMPANIES_ERROR', payload: error })
    }
}


export const cleanCompaniesAction = () => (dispatch, getState) => {
        
    try{
        dispatch({
            type: "CLEAN_COMPANIES_DATA",
        });
    } catch (error) {
        console.log(error);
        dispatch({ type: 'SET_COMPANIES_ERROR', payload: error })
    }
}