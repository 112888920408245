import { httpPost, httpGetError, httpGet } from '../http';
import * as CompaniesActions from './CompaniesActions';

export const getSession = (login, history) => (dispatch) => {
        
    /*dispatch({
        type: "SESSION_LOADING",
    });*/

    httpPost('/user/login', login, null).then(
        resp => {
            dispatch({ type: 'SET_SESSION', payload: resp.data })
            localStorage.setItem('session-token', resp.data._id);
            history.push('/');
        }
    ).catch(
        error => {
            console.log(error);
            let payloadError = httpGetError(error);
            if (payloadError.errorCode === 401){
                payloadError.errorMsg = 'Correo o contraseña incorrectos!'
            }
            dispatch({
                type: "SESSION_ERROR",
                payload: payloadError
            });
        }
    );
}

export const valSession = (history, opt) => (dispatch) => {
    /*dispatch({
        type: "SESSION_LOADING",
    });*/
    
    if(localStorage.getItem('session-token')){
        httpGet('/session/id/' + localStorage.getItem('session-token'), null).then(
            resp => {
                dispatch({ type: 'SET_SESSION', payload: resp.data })

                if (opt === 1){
                    history.push('/home');
                }
            }
        ).catch(
            error => {
                console.log(error);
                let payloadError = httpGetError(error);
                if (payloadError.errorCode === 404){
                    if (opt === 1){
                        localStorage.removeItem('session-token');
                    }
                    payloadError.errorMsg = 'Su sesion ha caducado!'
                    if (opt === 2){
                        history.push('/login');
                    }
                }
                dispatch({
                    type: "SESSION_ERROR",
                    payload: payloadError
                });                
            }
        );
    } else {
        if (opt === 2){
            history.push('/login');                
        }
    }
}

export const closeSession = () => (dispatch) =>{    
    dispatch({
        type: "CLEAN_SESSION",
    });
}
