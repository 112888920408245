import React, {useEffect, useState} from 'react';
import { httpGet } from '../../http';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EyeOutlined, SettingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const Box = (props) => {
    const { pos } = props;
    return(
        <Container style={{borderWidth: 0, borderColor: "black", borderStyle: "solid"}} className="box-container">
            <Row className="box-row" style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginLeft: 0, width: "100%", display: "flex", alignItems: "center"}}>
                <Col>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginBottom: "5px", fontSize: "14px"}} className="box-row">
                        <Col className="box-col"><div className="box-title">{pos.co} - {pos.host}</div></Col>
                    </Row>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginBottom: "5px", fontSize: "14px"}} className="box-row">
                        <Col className="box-col"><div className="box-title">{ pos.active ? <div style={{ color: 'green' }}>ACTIVO</div> : <div style={{ color: 'red' }}>DESACTIVADO</div> }</div></Col>
                    </Row>
                </Col>
            </Row>
            
        </Container>
    );
}

const ConfPos = (props) => {

    const { session } = props.SessionReducer;
    const { company } = props.history.location.state;

    const [ confPos, setConfPos ] = useState({
        data: [],
        loading: false,
        error: false,
        errorMsg: ""
    });

    useEffect(() => {

        if (session.user.role > 1){
            props.history.push('/home');
            return 0;
        }

        getPos();
    }, []);

    const getPos = async () => {
        const resp = await httpGet('/pos/company/' + company._id, session._id);
        if(!resp){
            return 0;
        }

        setConfPos({
            ...confPos,
            data: resp.data
        });
    }

    return(
        <div>
            <div>
                <div className='header'>
                    <div className="page-title2">
                        CONFIGURACIÓN DE SERVIDORES PDV - { company.name }:
                    </div>
                    {/*
                    <div className="refresh">
                        <ReloadOutlined className='refresh-icon' />
                    </div>
                    */}
                </div>
                <div className="home-component-container">
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>NOMBRE</th>
                                <th>ACTIVO</th>
                                <th>CONFIGURAR</th>
                            </tr>
                        </thead>
                        <tbody>
                        {confPos.data.map(
                            (pos, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{pos.co} - {pos.host}</td>
                                        <td>{ pos.active ? <div style={{ color: 'green' }}>ACTIVO</div> : <div style={{ color: 'red' }}>DESACTIVADO</div> }</td>
                                        <td><Link to={{ pathname: '/conf-detail', state: { pos: pos } }}><div style={{fontSize: "25px", marginTop: "-10px", color: "gray"}}><SettingOutlined /></div></Link></td>
                                    </tr>
                                )
                            }
                        )}        
                        </tbody>
                    </table>
                    
                </div>
                <div className="boxes">
                    {confPos.data.map(
                        (pos, index) => {
                            return(
                                <div key={index} className="">
                                    <Link to={{ pathname: '/conf-detail', state: { pos: pos } }}>
                                        <Box pos={pos} />
                                    </Link>
                                </div>            
                            )
                        }
                    )}
                    <br />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ SessionReducer }) => {
    return {
        SessionReducer
    }
};

export default connect(mapStateToProps)(ConfPos);