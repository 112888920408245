import React, { useEffect, useState } from 'react';
import { Spinner, Alert, Button, Container, Row, Col} from 'react-bootstrap';
import { connect } from 'react-redux';
import { httpGet, httpPost } from '../../http';
import './Profile.css';

const TextBox = (props) => {
    return(
        <input className='profile-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required={ props.required } disabled={props.disabled} />
    );
}

const Field = (props) => {
    return(
        <Row className='profile-field'>
            <Col sm={12} md={4} >
                <div className='profile-field-name'>{ props.label }</div>
            </Col>
            <Col sm={12} md={4}>
                <TextBox type={props.type} name={props.name} value={props.value} onChange={props.onChange} required={ props.required } disabled={ props.disabled } />
            </Col>
            <Col sm={12} md={4}>
                <div className='profile-field-hint'>{ props.hint }</div>
            </Col>
        </Row>
    );
}

const Profile = (props) => {

    const { user : sessionUser } = props.SessionReducer.session;
    const { session } = props.SessionReducer;

    const [ profile, setProfile ] = useState({
        data: {},
        loading: false,
        error: false,
        errorMsg: ''
    });

    const [ form, setForm ] = useState({
        name: '',
        lastName: '',
        email1: '',
        email2: '',
        password1: '',
        password2: '',
        error: false,
        success: false,
        result: ''
    });

    useEffect(() => {
        getUser();
    }, []);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    const getUser = async () => {
        const user = await httpGet('/user/id/' + sessionUser._id, session._id);
        if(user.data){
            setForm({
                name: user.data.name,
                lastName: user.data.lastName,
                email1: user.data.email,
                email2: user.data.email
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{

            if(form.email1 !== form.email2){
                setForm({
                    ...form,
                    success: false,
                    error: true,
                    result: 'Los correos electrónicos no coinciden.'
                });
                window.scrollTo(0, 0);
                return 0;
            }
    
            if(form.password1 !== form.password2){
                setForm({
                    ...form,
                    success: false,
                    error: true,
                    result: 'Las contraseñas no coinciden.'
                });
                window.scrollTo(0, 0);
                return 0;
            }
    
            let data = { ...sessionUser };
            data.name = form.name;
            data.lastName = form.lastName;
            data.email = form.email1;
            data.password = form.password1;
    
            const user = await httpPost('/user', data, session._id);
            if (user) {
                setForm({
                    name: user.data.name,
                    lastName: user.data.lastName,
                    email1: user.data.email,
                    email2: user.data.email,
                    password1: '',
                    password2: '',
                    success: true,
                    error: false,
                    result: 'Se ha actualizado el perfil correctamente.'
                });
            }
        } catch (error) {
            setForm({
                ...form,
                error: true,
                success: false,
                result: 'Ocurrio un error al actualizar el perfil.'
            });
        }

        window.scrollTo(0, 0);
    }

    return(
        <Container>
            
                <div className="page-title2">
                    Perfil de usuario
                </div>
                { form.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <div className='page-body'>
                    { ((form.error) || (form.success)) &&
                        <>
                            <Alert variant={ form.success ? 'success' : 'danger' }>{ form.result }</Alert>
                        </>
                    }
                    <form className='signup-form' onSubmit={handleSubmit} >
                        <Field type='text' label='Nombre (*)' hint='Dijite su(s) nombre(s)' name='name' value={form.name} onChange={handleChange} required={ true } />
                        <Field type='text' label='Apellidos (*)' hint='Dijite su(s) apellido(s)' name='lastName' value={form.lastName} onChange={handleChange} required={ true } />
                        <Field type='email' label='Correo electrónico(*)' hint='Dijite su correo electrónico' name='email1' value={form.email1} onChange={handleChange} required={ true } />
                        <Field type='email' label='Repita su Correo electrónico(*)' hint='Dijite su correo electrónico nuevamente' name='email2' value={form.email2} onChange={handleChange} required={ true } />
                        <Field type='password' label='Contraseña(*)' hint='Dijite una contraseña' name='password1' value={form.password1} onChange={handleChange} />
                        <Field type='password' label='Repita la contraseña(*)' hint='Dijite de nuevo la contraseña' name='password2' value={form.password2} onChange={handleChange} />
                        <Button type='submit' style={{ marginTop: 20 }} >Aceptar</Button>
                    </form>
                </div>
                }
            
        </Container>
    )
}

const mapStateToProps = ({ SessionReducer }) => {
    return {
        SessionReducer
    }
};


export default connect(mapStateToProps)(Profile);
