import React, {useEffect, useState} from 'react';
import { httpGet } from '../../http';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const CompanyBox = (props) => {
    const { company } = props;
    return(
        <Container style={{borderWidth: 0, borderColor: "black", borderStyle: "solid"}} className="box-container">
            <Row className="box-row" style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginLeft: 0, width: "100%", display: "flex", alignItems: "center"}}>
                <Col>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginBottom: "5px", fontSize: "14px"}} className="box-row">
                        <Col className="box-col"><div className="box-title">{company.name}</div></Col>
                    </Row>
                </Col>
            </Row>
            
        </Container>
    );
}

const Conf = (props) => {

    const { session } = props.SessionReducer;

    const [ conf, setConf ] = useState({
        data: [],
        loading: false,
        error: false,
        errorMsg: ""
    });

    useEffect(() => {
        
        if (session.user.role > 1){
            props.history.push('/home');
            return 0;
        }

        getCompanies();
    }, []);

    const getCompanies = async () => {
        const resp = await httpGet('/company/user/' + session.user._id, session._id);
        if(!resp){
            return 0;
        }

        setConf({
            ...conf,
            data: resp.data
        });
    }

    return(
        <div>
            <div>
                <div className='header'>
                    <div className="page-title2">
                        CONFIGURACIÓN DE EMPRESAS:
                    </div>
                    {/*
                    <div className="refresh">
                        <ReloadOutlined className='refresh-icon' />
                    </div>
                    */}
                </div>
                <div className="home-component-container">
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>NOMBRE</th>
                                <th>VER</th>
                            </tr>
                        </thead>
                        <tbody>
                        {conf.data.map(
                            (company, index) => {
                                return(
                                    <>
                                        { company.active &&
                                        <tr key={index}>
                                            <td>{company.name}</td>
                                            <td><Link to={{ pathname: '/conf-pos', state: { company: company } }}><div style={{fontSize: "25px", marginTop: "-10px", color: "blue"}}><EyeOutlined /></div></Link></td>
                                        </tr>
                                        }
                                    </>
                                )
                            }
                        )}        
                        </tbody>
                    </table>
                    
                </div>
                <div className="boxes">
                    {conf.data.map(
                        (company, index) => {
                            return(
                                <>
                                    { company.active &&
                                    <div key={index} className="">
                                        <Link to={{ pathname: '/conf-pos', state: { company: company } }}>
                                            <CompanyBox company={company} />
                                        </Link>
                                    </div>
                                    }
                                </>
                            )
                        }
                    )}
                    <br />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ SessionReducer }) => {
    return {
        SessionReducer
    }
};

export default connect(mapStateToProps)(Conf);