
export const showBackButton = () => (dispatch, getState) => {
    console.log("SHOW");
    dispatch({
        type: "SET_TRUE_SHOW_BACK_BUTTON",
    });
}

export const hideBackButton = () => (dispatch, getState) => {
    console.log("HIDE");
    dispatch({
        type: "SET_FALSE_SHOW_BACK_BUTTON",
    });
}