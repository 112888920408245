import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { httpGet } from '../../http';
import Box from '../../components/box/Box';
import { connect } from 'react-redux';
import * as PhasesActions from '../../actions/PhasesActions';
import * as SelectorsActions from '../../actions/SelectorsActions';
import * as PosServersActions from '../../actions/PosServersActions';
import * as GlobalActions from '../../actions/GlobalActions';
import { formatDateMin, formatDate } from '../../misc/date';
import { EyeOutlined } from '@ant-design/icons';
import IconState from '../../components/IconState';
import IconState2 from '../../components/IconState2';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import './PosServers.css';
import PdfView from '../../components/pdfView/PdfView';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from '../../components/button/Button';

const PosBox = (props) => {
    const { posServer } = props;
    return(
        <Container style={{borderWidth: 0, borderColor: "black", borderStyle: "solid"}} className="box-container">
            <Row className="box-row" style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginLeft: 0, width: "100%", display: "flex", alignItems: "center"}}>
                <Col className="box-col" xs={3}>
                    <IconState2 state={posServer.state} />
                </Col>
                <Col>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginBottom: "5px", fontSize: "14px"}} className="box-row">
                        <Col className="box-col"><div className="box-title">{ posServer.type === 1 ? "PDV" : posServer.type === 3 ? "PDV SECUNDARIO" : "RESTAURANTE" } - {posServer.co} - {posServer.host}</div></Col>
                    </Row>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", fontSize: "14px"}} className="box-row">
                        <Col className="box-col">{ posServer.state !== 2 ? formatDate(new Date(posServer.createAt)) : "" }</Col>
                    </Row>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", fontSize: "14px"}} className="box-row">
                        <Col className="box-col">{ posServer.message }</Col>
                    </Row>
                </Col>
            </Row>
            
        </Container>
    );
}

const { updateSelectors, setPosServerSelectedAction, setMainLogIdAction } = SelectorsActions;
const { getPosServersAction } = PosServersActions;
const { setPhasesLoadingAction } = PhasesActions;
const { showBackButton, hideBackButton } = GlobalActions;

const PosServers = (props) => {

    const { company } = props.SelectorsReducer;
    const { data, error, errorMsg, loading } = props.PosServersReducer;
    const { setPosServerSelectedAction, getPosServersAction, setPhasesLoadingAction, showBackButton, hideBackButton } = props;
    const [ pdfData, setPdfData ] = useState({});    
    

    useEffect(() => {
        sessionStorage.setItem('location', 'pos');
        showBackButton();
        setMainLogIdAction(company.mainLogId);
        updateSelectors();
        getPosServersAction();
        //getPdf();


    }, []);

    const getPdf = async () => {
        try{
            const log = await httpGet("/log/user/log/" + company.mainLogId, props.SessionReducer.session._id);
            setPdfData(log.data);
        } catch(error) {
            console.log(error);
        }
    }

    const goToPhases = (e, posServer) => {
        e.preventDefault();
        setPosServerSelectedAction(posServer);
        setPhasesLoadingAction();
        props.history.push('/fases');
    }

    return(
        <div>
            
            { error ?
                <Alert className='alert' variant='danger'>{errorMsg}</Alert>
            :
            !loading &&
            <div style={{ marginBottom: '50px' }}>
                <div className="page-title2">
                    SEDES - { company.name } {<IconState state={company.state} />}
                </div>
                
                <div className="home-component-container">
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>C. O.</th>
                                <th>NOMBRE</th>
                                <th>TIPO</th>
                                <th>ESTADO</th>
                                <th>DETALLE</th>
                                <th>FECHA/HORA</th>
                                <th>VER</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.map(
                            (posServer, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{posServer.co}</td>
                                        <td>{posServer.host}</td>
                                        <td>{ posServer.type === 1 ? "PDV" : posServer.type === 3 ? "PDV SECUNDARIO" : "RESTAURANTE" }</td>
                                        <td><IconState state={posServer.state} /></td>
                                        <td>{ posServer.state == 3 ? "EN ESPERA..." : posServer.message}</td>
                                        <td>{formatDate(new Date(posServer.createAt))}</td>
                                        <td>{ (posServer.state !== 2) && <Link to={{ pathname: '' }} onClick={ (e) => { goToPhases(e, posServer) } } ><div style={{fontSize: "25px", marginTop: "-10px", color: "blue"}}><EyeOutlined /></div></Link> } </td>
                                    </tr>
                                )
                            }
                        )}        
                        </tbody>
                    </table>
                </div>
                {
                <div className="boxes">
                    {data.map(
                        (posServer, index) => {
                            return(
                                <div key={index} className="">
                                    {
                                        posServer.state !== 2 ?
                                        <Link to={{ pathname: '', }} onClick={ (e) => { goToPhases(e, posServer) } } >
                                            <PosBox posServer={posServer} />
                                        </Link>
                                        :
                                        <PosBox posServer={posServer} />
                                    }
                                </div>            
                            )
                        }
                    )}
                    { /*company.state !== 0 &&
                        <div>
                        {
                            (pdfData.company !== null && pdfData.company !== undefined) ?
                                <div style={ { marginTop: 40 } }>
                                    <PDFDownloadLink document={<PdfView log={pdfData} />} fileName="informe.pdf">
                                        {
                                            ({ blob, url, loading, error }) => {
                                                return(loading ? 'Cargando informe...' : <Button>Descargar informe</Button>);
                                            }
                                        }
                                    </PDFDownloadLink>
                                </div>
                            :
                            <div style={ { marginTop: 40 } }>
                                Cargando informe...
                            </div>
                        }
                        </div>
                    */}
                </div>
                }
            </div>
            }
        </div>        
    );
}

const mapStateToProps = ({ CompaniesReducer, PosServersReducer, SessionReducer, SelectorsReducer, GlobalReducer }) => {
    return {
        CompaniesReducer,
        PosServersReducer,
        SessionReducer,
        SelectorsReducer,
        GlobalReducer
    }
};

const mapDispatchToProps = {
    setPosServerSelectedAction,
    getPosServersAction,
    setMainLogIdAction,
    setPhasesLoadingAction,
    updateSelectors,
    showBackButton,
    hideBackButton
}

export default connect(mapStateToProps, mapDispatchToProps)(PosServers);