import React, { useState, useEffect } from 'react';
import Box from '../../components/box/Box';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner, Button, Alert } from 'react-bootstrap';
import './Home.css';
import { ReloadOutlined, EyeOutlined, CheckCircleTwoTone, CloseCircleTwoTone, MinusCircleTwoTone, LoadingOutlined, FilePdfOutlined } from '@ant-design/icons';
import * as PosServersActions from '../../actions/PosServersActions';
import * as SelectorsActions from '../../actions/SelectorsActions';
import * as GlobalActions from '../../actions/GlobalActions';
import { formatDate } from '../../misc/date';
import IconState from '../../components/IconState';
import IconState2 from '../../components/IconState2';
import { getURL, httpGet } from '../../http';

const CompanyBox = (props) => {
    const { company } = props;
    return(
        <Container style={{borderWidth: 0, borderColor: "black", borderStyle: "solid"}} className="box-container">
            <Row className="box-row" style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginLeft: 0, width: "100%", display: "flex", alignItems: "center"}}>
                <Col className="box-col" xs={3}>
                    <IconState2 state={company.state} />
                </Col>
                <Col>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", marginBottom: "5px", fontSize: "14px"}} className="box-row">
                        <Col className="box-col"><div className="box-title">{company.name}</div></Col>
                    </Row>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", fontSize: "14px", marginBottom: "5px"}} className="box-row">
                        <Col className="box-col">{company.message}</Col>
                    </Row>
                    <Row style={{borderWidth: 0, borderColor: "black", borderStyle: "solid", fontSize: "14px"}} className="box-row">
                        <Col className="box-col">{formatDate(new Date(company.createAt))}</Col>
                    </Row>
                </Col>
            </Row>
            
        </Container>
    );
}

const { setCompanySelectedAction } = SelectorsActions;
const { setPosServersLoadingAction } = PosServersActions;
const { showBackButton, hideBackButton } = GlobalActions;

const Home = (props) => {

    const { data, loading, error, errorMsg } = props.CompaniesReducer;
    const { getCompaniesAction, setCompanySelectedAction, setPosServersLoadingAction, showBackButton, hideBackButton } = props;

    useEffect(() => {
        sessionStorage.setItem('location', 'home');
        hideBackButton();
    }, []);

    const goToPdf = async (e, mainId) => {
        e.preventDefault();
        try{
            const log = await httpGet("/log/user/log/" + mainId, props.SessionReducer.session._id);
            props.history.push({ pathname: '/pdf', state: { log: log.data } });
        } catch(error) {
            console.log(error);
        }
    }

    const goToPosServers = (e, company) => {
        e.preventDefault();
        setCompanySelectedAction(company);
        setPosServersLoadingAction();
        sessionStorage.setItem('currentCompanyId', company._id);
        props.history.push('/pos-servers');
    }

    return(
        <div>
            {/*
                loading ?
                    <div className="home-spinner">
                        <Spinner animation="border" variant="primary" />
                    </div>
                :
            */}
            { error ?
                <Alert className='alert' variant='danger'>{errorMsg}</Alert>
            :
            <div>
                <div className='header'>
                    <div className="page-title2">
                        EMPRESAS:
                    </div>
                    {/*
                    <div className="refresh">
                        <ReloadOutlined className='refresh-icon' />
                    </div>
                    */}
                </div>
                <div className="home-component-container">
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>NOMBRE</th>
                                <th>ESTADO</th>
                                <th>DETALLE</th>
                                <th>FECHA/HORA</th>
                                <th>VER</th>
                                {/*<th>INFORME</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                        {data.map(
                            (company, index) => {
                                if(company.active){
                                    return(
                                        <tr key={index}>
                                            <td>{company.name}</td>
                                            <td><IconState state={company.state} /></td>
                                            <td>{ company.state !== 2 ? company.message : 'SIN REGISTRO DE ACUMULACION ' }</td>
                                            <td>{formatDate(new Date(company.createAt))}</td>
                                            <td>{ company.state !== 2 && <Link to={{ pathname: '' }} onClick={(e) => { goToPosServers(e, company) }} ><div style={{fontSize: "25px", marginTop: "-10px", color: "blue"}}><EyeOutlined /></div></Link>}</td>
                                            {/*<td>{ company.state !== 2 && <Link to={{ pathname: '' }} onClick={ (e) => {goToPdf(e, company.mainLogId)}} ><div style={{fontSize: "25px", marginTop: "-10px", color: "red"}}><FilePdfOutlined /></div></Link>}</td>*/}
                                        </tr>
                                    )
                                }
                            }
                        )}        
                        </tbody>
                    </table>
                    
                </div>
                <div className="boxes">
                    {data.map(
                        (company, index) => {
                            return(
                                <>
                                    { company.active &&
                                    <div key={index} className="">
                                        <Link to={{ pathname: '' }} onClick={(e) => {goToPosServers(e, company)} }>
                                            <CompanyBox company={company} />
                                        </Link>
                                    </div>            
                                    }
                                </>
                            )
                        }
                    )}
                    <br />
                </div>
            </div>
            }
        </div>
    );
}

const mapDispatchToProps = {
    setCompanySelectedAction,
    setPosServersLoadingAction,
    showBackButton,
    hideBackButton
}

const mapStateToProps = ({ SessionReducer, CompaniesReducer }) => {
    return {
        SessionReducer,
        CompaniesReducer
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
